import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { validateToken } from 'src/redux-store/actions/user-actions'

import Loader from 'src/components/Loader'

import config from 'src/common/config'
import { getCookie, setCookie } from 'src/common/cookies'

const Auth = props => {
  const { children, user, location, history, validateTokenAction } = props

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tokenParam = queryParams.get('token')
    const authCookie = getCookie('auth_token')

    const validateToken = async token => {
      await validateTokenAction(token)
    }

    if (!authCookie && !tokenParam) {
      window.location.href = config.LOGIN_URL
    } else {
      const token = tokenParam || authCookie || user.token
      if (!authCookie || authCookie !== tokenParam)
        setCookie('auth_token', token)

      validateToken(token)
      queryParams.delete('token')
      history.replace(`${location.pathname}?${queryParams.toString()}`)
    }
  }, [
    history,
    location.pathname,
    location.search,
    user.token,
    validateTokenAction
  ])

  return isEmpty(user) ? <Loader /> : children
}
Auth.propTypes = {
  user: PropTypes.object,
  validateTokenAction: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.user.current
})
const mapDispatchToProps = {
  validateTokenAction: validateToken
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth))
